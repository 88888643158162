import {
   CartCoupon,
   InnerwellCartItem,
   InnerwellCartPayment,
   InnerwellCartTotals,
   InnerwellProductVariant,
   PaymentCode,
} from '@innerwell/dtos';
import { getErrorMessage, isInstallmentPaymentMethod } from '@innerwell/utils';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import Axios, { AxiosError } from 'axios';
import React, { useCallback, useRef, useState } from 'react';

import useThemedToast from '@/hooks/useThemedToast';

import { SelectedClinician } from '@/components/Cards/ScheduleClinicianCard';

import { webApiClient } from '@/api-client/apiClient';
import { getSupportedPaymentMethods } from '@/utils';
import { handleSentryException, handleSentryMessage } from '@/utils/sentry';

export type CartTotalItem = Omit<
   InnerwellCartPayment,
   'numOfPayments' | 'frequency' | 'code'
> & {
   numOfPayments: number;
   amount: number;
   discountAmount: number;
   amountWithoutDiscounts: number;
   code: PaymentCode;
};

export interface CartTotal {
   onceInFull: CartTotalItem;
   // week: CartTotalItem;
   installments?: CartTotalItem;
}
export type ProductType =
   | 'plan'
   | 'addon'
   | 'medical-addon'
   | 'followon'
   | 'missing-appointment';
export interface Cart {
   id: string | null;
   items: InnerwellCartItem[];
   totals: CartTotal | null;
   paymentMethod: PaymentCode | null;
   productType: ProductType | null;
   isInsuranceFlow: boolean | null;
   productCategoryId: string | null;
   error: string | null;
}

export type UseQueryMutation<ReturnT, Params> = UseMutationResult<
   ReturnT,
   unknown,
   Params
>;

export type CreateCartOptions = {
   items?: Pick<InnerwellCartItem, 'sku' | 'qty'>[];
   paymentMethod?: PaymentCode;
   force?: boolean;
   couponCode?: string;
   isInsuranceFlow?: boolean;
   // To which categoryId should main product in the cart belong.
   productCategoryId?: string;
};

const CartContext = React.createContext<{
   cart: Cart;
   createCart: (
      productType: ProductType,
      productSku?: string,
      params?: CreateCartOptions,
   ) => Promise<string | void>;
   addToCartMutationFn: UseQueryMutation<
      InnerwellCartItem,
      {
         sku: string;
         qty?: number;
      }
   >;
   removeFromCartMutationFn: UseQueryMutation<boolean, string>;
   applyCouponMutationFn: UseQueryMutation<boolean, string>;
   removeCouponMutationFn: UseQueryMutation<boolean, void>;
   setPaymentMethodMutationFn: UseQueryMutation<boolean, PaymentCode>;
   coupon: CartCoupon | null;
   isCartTotalsUpdating: boolean;
   isCartBusy: boolean; // any operation on cart
   selectedTherapist: SelectedClinician | null;
   setSelectedTherapist: (therapist: SelectedClinician) => void;
   selectedBasePlan: InnerwellProductVariant | null;
   setSelectedBasePlan: (plan: InnerwellProductVariant) => void;
   emptyCart: () => Promise<void>;
   isCartEmptying: boolean;
}>({
   cart: {
      id: null,
      items: [],
      totals: null,
      paymentMethod: null,
      productType: null,
      isInsuranceFlow: null,
      productCategoryId: null,
      error: null,
   },
   selectedTherapist: null,
   setSelectedTherapist: () => {
      return;
   },
   createCart: () => {
      return Promise.resolve();
   },
   addToCartMutationFn: {} as UseQueryMutation<
      InnerwellCartItem,
      {
         sku: string;
         qty?: number;
      }
   >,
   removeFromCartMutationFn: {} as UseQueryMutation<boolean, string>,
   applyCouponMutationFn: {} as UseQueryMutation<boolean, string>,
   removeCouponMutationFn: {} as UseQueryMutation<boolean, void>,
   setPaymentMethodMutationFn: {} as UseQueryMutation<boolean, PaymentCode>,
   coupon: null,
   isCartTotalsUpdating: false,
   isCartBusy: true,
   selectedBasePlan: null,
   setSelectedBasePlan: () => {
      return;
   },
   emptyCart: () => {
      return {} as Promise<void>;
   },
   isCartEmptying: false,
});

const getCartPayments = (paymentOptions: InnerwellCartTotals[]): CartTotal => {
   const onceInFullPO = paymentOptions.find(
      (po) => po.paymentMethod.frequency === PaymentCode.OnceInFull,
   );

   const freePO = paymentOptions.find(
      (po) => po.paymentMethod.code === PaymentCode.Free,
   );

   if (!freePO && !onceInFullPO) {
      throw new Error(
         'Payments options must return either free or in-full option',
      );
   }

   if (freePO && onceInFullPO) {
      throw new Error('Cannot return both free and in-full option');
   }

   const mainPaymentOption = (freePO || onceInFullPO) as InnerwellCartTotals;

   // @NOTE: trying to find the first, if multiple are selected we have to define the behavior
   const monthlyPO = paymentOptions.find((po) =>
      isInstallmentPaymentMethod(po.paymentMethod.code),
   );

   const cartTotalWithoutDiscounts = mainPaymentOption.items.reduce(
      (acc, item) => {
         return acc + item.basePrice * item.qty;
      },
      0,
   );
   return {
      onceInFull: {
         title:
            mainPaymentOption.paymentMethod.code === PaymentCode.Free
               ? 'Free'
               : mainPaymentOption.paymentMethod.title,
         amount: mainPaymentOption.cartTotal,
         // We get the discountAmount as a negative number
         discountAmount: Math.abs(mainPaymentOption.discountAmount ?? 0),
         amountWithoutDiscounts: cartTotalWithoutDiscounts,
         numOfPayments: 1,
         code: mainPaymentOption.paymentMethod.code,
      },
      // week: {
      //    title: weeklyPO.paymentMethod.title,
      //    amount:
      //       weeklyPO.cartTotal / parseInt(weeklyPO.paymentMethod.numOfPayments),
      //    amountWithoutDiscounts:
      //       cartTotalWithoutDiscounts /
      //       parseInt(weeklyPO.paymentMethod.numOfPayments),
      //    discountAmount: Math.abs(weeklyPO.discountAmount ?? 0),
      //    numOfPayments: parseInt(weeklyPO.paymentMethod.numOfPayments),
      // },
      installments: monthlyPO
         ? {
              title: monthlyPO.paymentMethod.title,
              amount:
                 monthlyPO.cartTotal /
                 parseInt(monthlyPO.paymentMethod.numOfPayments),
              amountWithoutDiscounts:
                 cartTotalWithoutDiscounts /
                 parseInt(monthlyPO.paymentMethod.numOfPayments),
              discountAmount: Math.abs(monthlyPO.discountAmount ?? 0),
              numOfPayments: parseInt(monthlyPO.paymentMethod.numOfPayments),
              code: monthlyPO.paymentMethod.code,
           }
         : undefined,
   };
};

export const cartIdIsDefined: (cartId: unknown) => asserts cartId is string = (
   cartId,
) => {
   if (!cartId) {
      throw new Error('Cart ID is not defined');
   }
};

const getMagentoErrMsg = (err: AxiosError) => {
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   return (err.response?.data as any)?.message || err.message;
};

export const CartProvider = ({ children }: { children: React.ReactNode }) => {
   const { toastError } = useThemedToast();
   const [cart, setCart] = React.useState<Cart>({
      id: null,
      items: [],
      totals: null,
      paymentMethod: null,
      productType: null,
      isInsuranceFlow: null,
      productCategoryId: null,
      error: null,
   });
   const [coupon, setCoupon] = useState<CartCoupon | null>(null);
   const [isCartTotalsUpdating, setIsCartTotalsUpdating] = useState(false);
   const [isCartEmptying, setIsCartEmptying] = useState(false);
   const [isCartCreating, setIsCartCreating] = useState(false);
   const [selectedTherapist, setSelectedTherapist] =
      useState<SelectedClinician | null>(null);

   // The user can choose base plan either foundation or extended
   const [selectedBasePlan, setSelectedBasePlan] =
      useState<InnerwellProductVariant | null>(null);

   const abortController = useRef<AbortController | null>(null);

   // Cart ID should always exist when adding/removing items
   // But for the initial sync (when creating the cart) we need
   // the provided cartId
   const updateCartTotalState = useCallback(
      async ({
         cartId,
         items,
      }: {
         cartId: string;
         items: InnerwellCartItem[];
      }) => {
         const newAbortController = new AbortController();

         setIsCartTotalsUpdating(true);

         if (abortController.current) {
            abortController.current.abort();
         }
         abortController.current = newAbortController;

         const cartPaymentMethods = getSupportedPaymentMethods(items);

         try {
            const paymentOptions = await webApiClient.cart.paymentMethods({
               params: {
                  cartId,
               },
               query: {
                  paymentMethods: cartPaymentMethods,
               },
               fetchOptions: {
                  signal: abortController.current?.signal,
               },
            });

            if (paymentOptions.body) {
               const cartTotal = getCartPayments(paymentOptions.body);
               setCart((prev) => ({
                  ...prev,
                  totals: cartTotal,
               }));
            }
            setIsCartTotalsUpdating(false);
         } catch (err) {
            if (Axios.isAxiosError(err)) {
               if (err.code === 'ERR_CANCELED') {
                  // The request was cancelled by AbortController
               } else {
                  handleSentryMessage(
                     `Error happened while fetching payment methods ${err.message}`,
                     'fatal',
                  );
                  // Additional error scope to determine the cause
                  handleSentryException(err);
               }
            } else if (err instanceof Error) {
               handleSentryException(err);
               toastError(getErrorMessage(err));
            }
            setIsCartTotalsUpdating(false);
         }
      },
      [toastError],
   );

   const createCart = useCallback(
      async (
         productType: ProductType,
         productSku?: string,
         {
            items: newItems,
            paymentMethod,
            force,
            couponCode,
            isInsuranceFlow,
            productCategoryId,
         }: CreateCartOptions = {},
      ) => {
         if (!cart.id || force) {
            try {
               setIsCartCreating(true);
               const res = await webApiClient.cart.createCart();
               const cartId = res.body;

               const items: InnerwellCartItem[] = [];

               if (productSku) {
                  const res = await webApiClient.cart.addToCart({
                     body: {
                        cartId,
                        cartItem: {
                           qty: 1,
                           sku: productSku,
                        },
                     },
                  });
                  const cartItem = res.body;

                  items.push(cartItem);
               }

               if (newItems) {
                  for (const item of newItems) {
                     const res = await webApiClient.cart.addToCart({
                        body: {
                           cartId,
                           cartItem: {
                              qty: item.qty,
                              sku: item.sku,
                           },
                        },
                     });

                     items.push(res.body);
                  }
               }

               if (paymentMethod) {
                  await webApiClient.cart.setPaymentMethodForCart({
                     params: {
                        cartId,
                     },
                     body: {
                        method: paymentMethod,
                     },
                  });
               }

               if (couponCode) {
                  const couponRes = await webApiClient.cart.setDiscountCoupon({
                     params: {
                        cartId,
                     },
                     body: {
                        coupon: couponCode,
                     },
                  });

                  if (couponRes.body === true) {
                     setCoupon({
                        code: couponCode,
                     });
                  }
               }

               setIsCartCreating(false);
               setCart((prev) => ({
                  ...prev,
                  id: cartId,
                  items,
                  productType,
                  isInsuranceFlow: isInsuranceFlow ?? false,
                  paymentMethod: paymentMethod ?? null,
                  productCategoryId: productCategoryId ?? null,
               }));

               await updateCartTotalState({
                  cartId,
                  items,
               });

               return cartId;
            } catch (e) {
               setCart((prev) => ({
                  ...prev,
                  error: getErrorMessage(e),
               }));
               setIsCartCreating(false);

               toastError(getErrorMessage(e));
            }
         }
      },
      [cart.id, toastError, updateCartTotalState],
   );

   const addToCartMutationFn = useMutation({
      mutationFn: async ({ sku, qty = 1 }: { sku: string; qty?: number }) => {
         cartIdIsDefined(cart.id);

         if (abortController.current) {
            abortController.current.abort();
         }

         const response = await webApiClient.cart.addToCart({
            body: {
               cartId: cart.id,
               cartItem: {
                  qty,
                  sku,
               },
            },
         });

         return response.body;
      },
      onSettled: async () => {
         cartIdIsDefined(cart.id);

         const cartItemsRes = await webApiClient.cart.getCartItems({
            params: {
               cartId: cart.id,
            },
         });
         const { body: cartItems } = cartItemsRes;
         setCart((prev) => ({
            ...prev,
            items: cartItems,
         }));

         await updateCartTotalState({
            cartId: cart.id,
            items: cartItems,
         });
      },
      onError: (err: AxiosError) => {
         const errMsg = getMagentoErrMsg(err);
         toastError(errMsg);
         handleSentryException(err);
      },
   });

   const setPaymentMethodMutationFn = useMutation({
      mutationFn: async (paymentMethod: PaymentCode) => {
         cartIdIsDefined(cart.id);

         if (abortController.current) {
            abortController.current.abort();
         }

         setCart((prev) => ({
            ...prev,
            paymentMethod,
         }));

         const response = await webApiClient.cart.setPaymentMethodForCart({
            params: {
               cartId: cart.id,
            },
            body: {
               method: paymentMethod,
            },
         });

         return response.body;
      },
      onSettled: async () => {
         cartIdIsDefined(cart.id);
         await updateCartTotalState({
            cartId: cart.id,
            items: cart.items,
         });
      },
      onError: (err: AxiosError) => {
         const errMsg = getMagentoErrMsg(err);
         toastError(errMsg);
         handleSentryException(err);
      },
   });

   const removeFromCartMutationFn = useMutation({
      mutationFn: async (sku: string) => {
         cartIdIsDefined(cart.id);

         if (abortController.current) {
            abortController.current.abort();
         }

         const cartItemsRes = await webApiClient.cart.getCartItems({
            params: {
               cartId: cart.id,
            },
         });
         const { body: cartItems } = cartItemsRes;
         const itemId = cartItems.find((item) => item.sku === sku)?.itemId;

         if (!itemId) {
            throw new Error(`Item not found in cart. SKU: ${sku}`);
         }

         const result = await webApiClient.cart.removeFromCart({
            params: {
               cartId: cart.id,
               itemId,
            },
         });

         return result.body;
      },
      onSettled: async () => {
         cartIdIsDefined(cart.id);

         const cartItemsRes = await webApiClient.cart.getCartItems({
            params: {
               cartId: cart.id,
            },
         });
         const { body: cartItems } = cartItemsRes;
         if (cartItems.length > 0) {
            await updateCartTotalState({
               cartId: cart.id,
               items: cartItems,
            });
         }

         setCart((prev) => ({
            ...prev,
            items: cartItems,
         }));
      },
      onError: (err: AxiosError) => {
         const errMsg = getMagentoErrMsg(err);
         toastError(errMsg);
         handleSentryException(err);
      },
   });

   const emptyCart = useCallback(async () => {
      if (cart.id) {
         setIsCartEmptying(true);

         const cartItemsRes = await webApiClient.cart.getCartItems({
            params: {
               cartId: cart.id,
            },
         });
         const { body: cartItems } = cartItemsRes;
         await Promise.all(
            cartItems.map(async (item) => {
               await removeFromCartMutationFn.mutateAsync(item.sku);
            }),
         );

         setCart((prev) => ({
            ...prev,
            items: [],
            totals: null,
         }));

         setIsCartEmptying(false);
      }
   }, [cart.id, removeFromCartMutationFn]);

   const applyCouponMutationFn = useMutation({
      mutationFn: async (couponCode: string) => {
         cartIdIsDefined(cart.id);

         const response = await webApiClient.cart.setDiscountCoupon({
            params: {
               cartId: cart.id,
            },
            body: {
               coupon: couponCode,
            },
         });

         return response.body;
      },
      onSuccess: (res, couponCode) => {
         if (res === true) {
            // coupon success
            setCoupon({
               code: couponCode,
            });

            cartIdIsDefined(cart.id);
            updateCartTotalState({
               cartId: cart.id,
               items: cart.items,
            });
         }
      },
      onError: (err: AxiosError) => {
         const errMsg = getMagentoErrMsg(err);
         toastError(errMsg);
         handleSentryException(err);
      },
   });

   const removeCouponMutationFn = useMutation({
      mutationFn: async () => {
         cartIdIsDefined(cart.id);

         const response = await webApiClient.cart.removeDiscountCoupon({
            params: {
               cartId: cart.id,
            },
         });
         return response.body;
      },
      onSuccess: () => {
         setCoupon(null);
         cartIdIsDefined(cart.id);
         updateCartTotalState({
            cartId: cart.id,
            items: cart.items,
         });
      },
      onError: (err: AxiosError) => {
         const errMsg = getMagentoErrMsg(err);
         toastError(errMsg);
         handleSentryException(err);
      },
   });

   return (
      <CartContext.Provider
         value={{
            cart,
            createCart,
            addToCartMutationFn,
            removeFromCartMutationFn,
            setPaymentMethodMutationFn,
            isCartTotalsUpdating,
            coupon,
            applyCouponMutationFn,
            removeCouponMutationFn,
            selectedTherapist,
            setSelectedTherapist,
            selectedBasePlan,
            setSelectedBasePlan,
            emptyCart,
            isCartEmptying,
            isCartBusy:
               isCartCreating ||
               isCartEmptying ||
               isCartTotalsUpdating ||
               addToCartMutationFn.isPending ||
               removeFromCartMutationFn.isPending ||
               setPaymentMethodMutationFn.isPending ||
               applyCouponMutationFn.isPending,
         }}
      >
         {children}
      </CartContext.Provider>
   );
};

export const useCart = () => {
   const context = React.useContext(CartContext);

   if (context === undefined) {
      throw new Error('useCart must be used within a CartProvider');
   }
   return context;
};
